@font-face {
    font-display: swap;
    font-family: 'ProximaNova';
    src: url('/fonts/Proxima-Nova-Alt-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-display: swap;
    font-family: 'ProximaNova';
    src: url('/fonts/Proxima-Nova-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: 'ProximaNova';
    src: url('/fonts/Proxima-Nova-Alt-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
